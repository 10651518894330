<template>
  <div class="container">

    <memlist-modal
      size="sm"
      ref="edit-form"
      :visible="show_modal" @close="show_modal = false"
      hide-footer
      >

      <SalesContactPerson
        ref="contact-editor"
        
        :contact="contact"
        :prospect="prospect"

        @created="created"
        @deleted="deleted"
        @call_clicked="call_clicked"
        @email_clicked="email_clicked"
      />
      
      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="save"
        @close="$refs['edit-form'].hide()"
        ref="saveButton"
      />

    </memlist-modal>

  </div>

</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import SalesContactPerson from '@/view/pages/ml/ths/sales/SalesContactPerson.vue';


export default {

  name: 'SalesContactPersonModal',

  props: ['contact','prospect'],
  emits: [],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    SalesContactPerson
  },

  computed: {
    
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    call_clicked(data, phone) {
      console.log('call_clicked again', data, phone);
      this.$emit('call_clicked', data, phone);
    },

    email_clicked(data, email) {
      this.$emit('email_clicked', data, email);
    },

    deleted(id) {
      this.$emit('deleted', id);
      this.hide();
    },

    created(data) {
      this.$refs['contact-editor'].save();
      this.$refs['saveButton'].stop();
      this.hide();

      this.$emit('created', data);
    },

    save() {
      this.$refs['contact-editor'].save();
      this.$refs['saveButton'].stop();
      this.hide();
    },

    show() {
      this.show_modal = true;
    },

    hide() {
      this.show_modal = false;
    },

    updated(item) {
      this.hide();
    }
  },

  mounted() {
    
  },

  data() {
    return {
      show_modal: false,
      creating: true,
    };
  }
};

</script>


<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
